/* eslint-disable no-console */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { Formik, Form } from 'formik';
import { withStyles, Button, Typography } from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormFields from './FormFields';

const styles = () => ({
  root: {
    width: '100%',
  },
  button: { width: '100%' },
});

function CustomForm({ classes, data }) {
  const fields = get(data, 'contentfulfields');
  // const emailReceiver = get(data, 'emailReceiver');
  // const thankYouMessage = get(data, 'thankYouMessage');
  const title = get(data, 'title');

  const handleFormSubmit = useCallback((values, actions) => {
    console.log(values);
    actions.resetForm();
  }, []);

  const initialValues = useMemo(
    () => ({
      emailAddress: '',
      radiobutton: '',
      phone: '',
      checkbox: '',
      dropdown: '',
      shortText: '',
      longText: '',
    }),
    [],
  );

  return (
    <div className={classes.root}>
      <Typography variant="h3">{title}</Typography>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ isSubmitting, isSubmitted, isValidating, isValid, errors }) => {
          return (
            <Form>
              <FormFields data={fields} errors={errors} isSubmitted={isSubmitted} />
              <Button
                variant="contained"
                disabled={isSubmitting || !isValid || isValidating}
                type="submit"
                color="primary"
                className={classes.button}>
                Submit
                <ArrowForwardIcon />
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

CustomForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageData: PropTypes.object,
};

CustomForm.defaultProps = {
  classes: {},
  data: null,
  pageData: null,
};

export default withStyles(styles)(CustomForm);
