import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/MailOutlineRounded';
import { useDrawer } from '../../../context/DrawerContext';
import Link from '../../Link';

const styles = theme => ({
  root: {},
  SubscriptionLink: {
    height: '80px',
    padding: theme.spacing(1.5),
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.header.text,
    '&:hover': {
      borderRadius: '0',
      textDecoration: 'none',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    height: '36px',
    width: '36px',
    backgroundColor: theme.palette.header.icons.backgroundColor,
    color: theme.palette.header.icons.color,
  },
});

function Subscription({ classes, className, text, to }) {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const handleClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const internal = useMemo(() => /^\/(?!\/)/.test(to), [to]);

  return (
    <div className={clsx(classes.root, className)}>
      <Link to={to} className={classes.SubscriptionLink} onClick={handleClick} target={internal ? '_self' : '_blank'}>
        <div className={classes.icon}>
          <MailIcon />
        </div>

        <Typography variant="body2">{t(text)}</Typography>
      </Link>
    </div>
  );
}
Subscription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
};

Subscription.defaultProps = {
  classes: {},
  className: null,
  text: 'CustobarSubscriptionForm.linkText',
  to: '#custobarSubscriptionForm',
};

export default withStyles(styles)(Subscription);
