import React, { useMemo } from 'react';

import { isEqual } from 'lodash';

import { siteIds, awards } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductLabels from './viinimaa';

function ProductLabels(props) {
  const awardImages = useMemo(() => {
    if (isEqual(siteId, siteIds.Viinimaa) || isEqual(siteId, siteIds.AnoraPro)) {
      return [{ key: 'Viinimaa yleinenmitali pokaali', value: 'viinimaa/mitalit/Viinimaa-yleinenmitali-pokaali.png' }];
    }
    return [{ key: 'Prisbelönt', value: 'folkofolk/labels/Medal_fof.png' }];
  }, []);

  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductLabels {...props} awardImagesData={awardImages} awards={awards.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaProductLabels {...props} awardImagesData={awardImages} awards={awards.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductLabels {...props} awardImagesData={awardImages} awards={awards.Folkofolk} />;
  }

  return null;
}

export default ProductLabels;
