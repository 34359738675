import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import AnoraProMemoryListActionButton from './anoraPro';

function MemoryListActionButton(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProMemoryListActionButton {...props} />;
  }

  return null;
}

export default MemoryListActionButton;
