import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaMemoryListDialog from './viinimaa';

function MemoryListDialog(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaMemoryListDialog location="memoryList" {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaMemoryListDialog location="memoryList" {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaMemoryListDialog {...props} />;
  }

  return null;
}

export default MemoryListDialog;
