import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';

import ContactPersonModule from '../../ContactPersonModule';

const styles = theme => ({
  root: {},
  personModuleRoot: {
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: ({ slider }) => {
      return slider ? '100%' : '50%';
    },
    flexBasis: ({ slider }) => {
      return slider ? '100%' : '50%';
    },
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: ({ slider }) => {
        return slider ? '25%' : '33.33%';
      },
      flexBasis: ({ slider }) => {
        return slider ? '25%' : '33.33%';
      },
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: ({ slider }) => {
        return slider ? '25%' : '25%';
      },
      flexBasis: ({ slider }) => {
        return slider ? '25%' : '25%';
      },
    },
  },
  content: {
    flexDirection: 'column',
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  text: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
});

function PersonListElement({ classes, className, data, slider, ...otherProps }) {
  if (!data) return null;
  return (
    <ContactPersonModule
      {...otherProps}
      className={clsx(classes.root, className)}
      data={data}
      classes={{
        root: classes.personModuleRoot,
        content: classes.content,
        imageContainer: classes.imageContainer,
        text: classes.text,
      }}
    />
  );
}

PersonListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  slider: PropTypes.bool,
};

PersonListElement.defaultProps = {
  classes: {},
  className: null,
  data: null,
  slider: false,
};

export default withStyles(styles)(PersonListElement);
