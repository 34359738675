import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { get, map, find, isEmpty, filter } from 'lodash';
import { useContent } from '../context/ContentContext';
import { getWindowHref, getWindowLocationSearch } from '../../utils/windowUtils';

export function useMemoryList() {
  const [cookies, setCookie] = useCookies(['memoryList_product']);
  const { getContentData } = useContent();
  const productInMemoryList = useMemo(() => get(cookies, 'memoryList_product', []), [cookies]);

  const addProductToMemoryList = useCallback(
    product => {
      const productList = get(cookies, 'memoryList_product', []);
      const itemIsMissing = isEmpty(find(productList, { sku: get(product, 'sku') }));
      if (itemIsMissing) {
        productList.push({ sku: get(product, 'sku') });
        setCookie('memoryList_product', productList, { path: '/', maxAge: 126144000 });
      }
      return cookies;
    },
    [cookies, setCookie],
  );

  const removeProductFromMemoryList = useCallback(
    product => {
      const itemIsMissing = isEmpty(find(productInMemoryList, { sku: get(product, 'sku') }));

      if (!itemIsMissing) {
        const p = filter(productInMemoryList, item => get(item, 'sku') !== get(product, 'sku'));
        setCookie('memoryList_product', p, { path: '/', maxAge: 126144000 });
      }

      return cookies;
    },
    [cookies, productInMemoryList, setCookie],
  );

  const isProductInMemoryList = useCallback(
    product => {
      return !isEmpty(find(productInMemoryList, { sku: get(product, 'sku') }));
    },
    [productInMemoryList],
  );

  const getProductsFromUrl = useCallback(
    (pageData, products = null) => {
      const queryString = getWindowLocationSearch();
      const params = new URLSearchParams(queryString);
      let skus = params.get('products');

      if (isEmpty(skus)) {
        return null;
      }
      skus = skus.split(',');

      if (products === null) {
        const channel = get(pageData, 'primaryChannel');
        const contentData = getContentData(channel);
        products = get(contentData, 'products');
      }

      const productList = filter(products, product => {
        return !isEmpty(filter(skus, sku => sku === get(product, 'sku')));
      });
      return productList;
    },
    [getContentData],
  );

  const getProductsInMemoryList = useCallback(
    pageData => {
      const channel = get(pageData, 'primaryChannel');
      const contentData = getContentData(channel);
      const products = get(contentData, 'products');

      const productInUrl = getProductsFromUrl(pageData, products);

      if (!isEmpty(productInUrl)) {
        return productInUrl;
      }

      const productList = filter(products, product => {
        return !isEmpty(filter(productInMemoryList, item => get(item, 'sku') === get(product, 'sku')));
      });

      return productList;
    },
    [getContentData, getProductsFromUrl, productInMemoryList],
  );

  const generateMemoryListShareUrl = useCallback(() => {
    const href = getWindowHref();

    const queryString = getWindowLocationSearch();
    const params = new URLSearchParams(queryString);
    let skus = params.get('products');

    if (isEmpty(skus)) {
      skus = map(productInMemoryList, 'sku').join(',');
    }

    return `${href}?products=${skus}`;
  }, [productInMemoryList]);

  const prodcutCountInMemoryList = useMemo(() => {
    return productInMemoryList.length;
  }, [productInMemoryList]);

  return {
    addProductToMemoryList,
    removeProductFromMemoryList,
    isProductInMemoryList,
    getProductsInMemoryList,
    getProductsFromUrl,
    generateMemoryListShareUrl,
    prodcutCountInMemoryList,
  };
}
