import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, toNumber } from 'lodash';
import { Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { convertToPrice } from '../../../../../utils/productUtils';
import Visible from '../../../Visible';

const styles = theme => ({
  root: {
    minHeight: theme.spacing(3),
    '@media print': {
      ...get(theme, 'palette.productPage.productInfo.mediaPrint.price', {}),
    },
  },
  price: {
    fontWeight: '700',
    fontFamily: theme.palette.monopolyPrice.fontFamily,
  },
  reducedPrice: {
    fontSize: ({ variant }) => {
      if (isEmpty(variant)) {
        return '1.2rem';
      }
      return null;
    },
    color: theme.palette.monopolyPrice.reducedPriceColor,
    marginBottom: '0!important',
  },
  normPrice: {
    textDecoration: 'line-through',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
});

function ProductMonopolyPrice({ className, classes, productData, ...otherProps }) {
  const { t } = useTranslation();

  const normPrice = get(productData, 'attributes.monopoly_price.value');
  const reducedPrice = get(productData, 'attributes.monopoly_reduced_price.value');
  const deposit = get(productData, 'attributes.debosit.value', null);
  const monopolyUrl = get(productData, 'attributes.monopoly_url.value');

  const activateReducedPrice = useMemo(() => {
    if (!isEmpty(normPrice) && !isEmpty(reducedPrice)) {
      return toNumber(normPrice) > toNumber(reducedPrice);
    }
    return false;
  }, [normPrice, reducedPrice]);

  const monopolyPrice = useMemo(() => {
    if (isEmpty(normPrice)) {
      return null;
    }
    return convertToPrice(normPrice);
  }, [normPrice]);

  const monopolyReducedPrice = useMemo(() => {
    if (isEmpty(reducedPrice)) {
      return null;
    }
    return convertToPrice(reducedPrice);
  }, [reducedPrice]);

  let price;
  if (activateReducedPrice) {
    price = monopolyReducedPrice;
  } else {
    price = monopolyPrice;
  }

  return (
    <div className={clsx(className, classes.root)}>
      <Visible visible={!isEmpty(monopolyUrl)}>
        {!isEmpty(price) && (
          <Typography
            {...otherProps}
            className={clsx(
              classes.price,
              className,
              activateReducedPrice ? classes.reducedPrice : null,
              !isEmpty(deposit) ? classes.reducedPrice : null,
            )}>
            {`${price} ${t('ProductMonopolyPrice.currency.symbol')}`}
          </Typography>
        )}
        {activateReducedPrice && (
          <Typography className={clsx(classes.normPrice, isEmpty(deposit) ? classes.marginBottom : null)}>
            {`${monopolyPrice} ${t('ProductMonopolyPrice.currency.symbol')}`}
          </Typography>
        )}

        {!isEmpty(deposit) && (
          <Typography variant="body2" className={classes.marginBottom}>{`+ pant ${toNumber(deposit)} ${t(
            'ProductMonopolyPrice.currency.symbol',
          )}`}</Typography>
        )}
      </Visible>
    </div>
  );
}

ProductMonopolyPrice.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
};

ProductMonopolyPrice.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default withStyles(styles)(ProductMonopolyPrice);
