import { GraphQLClient, gql } from 'graphql-request';

import { useInfiniteQuery } from 'react-query';
import {
  isEmpty,
  set,
  get,
  compact,
  map,
  find,
  reduce,
  isEqual,
  keyBy,
  filter,
  includes,
  sortBy,
  isArray,
  merge,
} from 'lodash';

import { siteIds } from '../../constants';
import { siteId } from '../../sites';
import { useContent } from '../context/ContentContext';

const attributesBySite = (() => {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return [
      'alcohol_type',
      'country_of_origin',
      'brand_ecomm',
      'fullbodiness',
      'packaging_type',
      'grapes',
      'sweetness',
      'product_classification',
      'alko_food_recommendations',
    ];
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return [
      'alcohol_type',
      'country_of_origin',
      'brand',
      'fullbodiness',
      'packaging_type',
      'grapes',
      'sweetness',
      'product_classification',
      'alko_food_recommendations',
    ];
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return [
      'alcohol_type',
      'country_of_origin',
      'packaging_type',
      'grapes',
      'monopoly_sortiment',
      'product_classification',
      'packing_material',
      'folk_o_folk_food_recommendations',
      'brand_ecomm',
    ];
  }
  return [];
})();

const client = new GraphQLClient(`${process.env.GATSBY_MAGENTO_HOST}/graphql`);

const productQuery = gql`
  query ProductsByFilters($pageSize: Int!, $filter: ProductAttributeFilterInput, $currentPage: Int!) {
    products(search: "", sort: { name: ASC }, filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
      total_count
      page_info {
        page_size
        current_page
      }
      items {
        sku
        name
      }
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
    }
  }
`;

const productFiltersReducer = (productFilters, filterValue, filterId) => {
  if (isEmpty(filterValue)) {
    return productFilters;
  }
  if (isArray(filterValue)) {
    set(productFilters, filterId, { from: get(filterValue, 0), to: get(filterValue, 1) });
  } else {
    set(productFilters, filterId, { eq: filterValue });
  }
  return productFilters;
};

const createProductFilters = filters => {
  return reduce(filters, productFiltersReducer, {});
};

const fetchProductsByFilter = async (store, defaultFilters, locationFilters, currentPage) => {
  const productData = await client.request(
    productQuery,
    {
      pageSize: 30,
      currentPage,
      filter: createProductFilters(merge({}, defaultFilters, locationFilters)),
    },
    {
      store,
    },
  );
  return get(productData, 'products');
};

const getNextPage = productData => {
  const currentPage = get(productData, 'page_info.current_page');
  const pageSize = get(productData, 'page_info.page_size');
  const totalCount = get(productData, 'total_count');
  const nextPage = currentPage + 1;
  if (totalCount > currentPage * pageSize) {
    return nextPage;
  }
  return null;
};

const getTotalCount = productData => {
  return get(productData, 'total_count');
};

const getFilters = productData => {
  let aggregations = filter(get(productData, 'aggregations'), aggregation => {
    return includes(attributesBySite, get(aggregation, 'attribute_code'));
  });

  aggregations = map(aggregations, aggregation => {
    const count = get(aggregation, 'count');
    const label = get(aggregation, 'label');
    const attributeCode = get(aggregation, 'attribute_code');
    const options = filter(sortBy(get(aggregation, 'options'), 'label'), option => {
      return !isEqual(get(option, 'value'), '0');
    });
    return {
      count,
      label,
      options,
      attribute_code: attributeCode,
    };
  });

  return keyBy(aggregations, 'attribute_code');
};

const getProducts = (productData, contentData) => {
  let products = get(productData, 'items');
  products = map(products, product => {
    return find(get(contentData, 'products'), { sku: get(product, 'sku') });
  });
  return compact(products);
};

export const useFetchProductsByFilters = (channel = {}, defaultFilters = {}, locationFilters = {}) => {
  const { getContentData } = useContent();

  const contentData = getContentData(channel);
  const store = get(channel, 'store');

  return useInfiniteQuery(
    ['fetchProductsByFilter', store, defaultFilters, locationFilters],
    async ({ pageParam: currentPage = 1 }) => {
      const productData = await fetchProductsByFilter(store, defaultFilters, locationFilters, currentPage);
      return {
        nextPage: getNextPage(productData),
        totalCount: getTotalCount(productData),
        filters: getFilters(productData),
        products: getProducts(productData, contentData),
      };
    },
    {
      enabled: !isEmpty(store),
      staleTime: 1000 * 60 * 5,
      getNextPageParam: lastPage => get(lastPage, 'nextPage'),
      retry: false,
    },
  );
};
