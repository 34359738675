import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import Visible from '../../../shared/Visible';

import { useMemoryList } from '../../../hooks/useMemoryList';

import { addToCart, removeFromCart } from '../../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: '0',
    right: '0',

    '@media print': {
      display: 'none',
    },
  },
  addToList: {
    ...get(theme, 'palette.memoryListButton.actionButton.addToList', {}),
  },
  removefromList: {
    ...get(theme, 'palette.memoryListButton.actionButton.removefromList', {}),
  },
  inList: {
    ...get(theme, 'palette.memoryListButton.actionButton.inList', {}),
  },
});

function MemoryListActionButton({ className, classes, productData, size, hasRemoveAction, ...otherProps }) {
  const { t } = useTranslation();

  const { addProductToMemoryList, removeProductFromMemoryList, isProductInMemoryList } = useMemoryList();

  const alreadyAdded = isProductInMemoryList(productData);

  const addToMemoryList = useCallback(() => {
    if (!alreadyAdded) {
      addProductToMemoryList(productData);
      addToCart(productData);
    }
  }, [alreadyAdded, productData, addProductToMemoryList]);

  const removeFromMemoryList = useCallback(() => {
    if (alreadyAdded) {
      removeProductFromMemoryList(productData);
      removeFromCart(productData);
    }
  }, [alreadyAdded, removeProductFromMemoryList, productData]);

  return (
    <>
      {!alreadyAdded && (
        <Tooltip title={t(`MemoryList.MemoryListButton.actions.add.label`)} arrow placement="top">
          <IconButton
            aria-label={t(`MemoryList.MemoryListButton.actions.add.label`)}
            className={clsx(classes.root, className, classes.addToList)}
            {...otherProps}
            onClick={addToMemoryList}>
            <PlaylistAddIcon fontSize={size} />
          </IconButton>
        </Tooltip>
      )}
      {alreadyAdded && (
        <>
          <Visible hidden={hasRemoveAction}>
            <Tooltip title={t(`MemoryList.MemoryListButton.actions.alreadyAdded.label`)} arrow placement="top">
              <IconButton
                aria-label={t(`MemoryList.MemoryListButton.actions.alreadyAdded.label`)}
                className={clsx(classes.root, className, classes.inList)}
                onClick={removeFromMemoryList}
                {...otherProps}>
                <PlaylistAddCheckIcon fontSize={size} />
              </IconButton>
            </Tooltip>
          </Visible>
          <Visible visible={hasRemoveAction}>
            <Tooltip title={t(`MemoryList.MemoryListButton.actions.remove.label`)} arrow placement="top">
              <IconButton
                aria-label={t(`MemoryList.MemoryListButton.actions.remove.label`)}
                className={clsx(classes.root, className, classes.removefromList)}
                {...otherProps}
                onClick={removeFromMemoryList}>
                <PlaylistRemoveIcon fontSize={size} />
              </IconButton>
            </Tooltip>
          </Visible>
        </>
      )}
    </>
  );
}

MemoryListActionButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  size: PropTypes.string,
  hasRemoveAction: PropTypes.bool,
};

MemoryListActionButton.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  size: 'medium',
  hasRemoveAction: false,
};

export default withStyles(styles)(MemoryListActionButton);
