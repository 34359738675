/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Stepper, Step, useTheme, withStyles, useMediaQuery } from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import ArrowForward from '@material-ui/icons/ArrowForwardRounded';
import { isEmpty, size, filter, isEqual } from 'lodash';
import Button from '../Button';
import Visible from '../Visible';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 0, 3),
    marginBottom: '0px',
    [theme.breakpoints.up(1344)]: {
      padding: theme.spacing(3, 0, 0, 0),
    },
  },

  carouselWrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  },

  carouselContentWrapper: {
    overflow: 'visible',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },

  carouselContent: {
    display: 'flex',
    transition: 'all 250ms linear',
    gap: ({ gap, hideText }) => {
      if (hideText || isEmpty(gap)) {
        return '0';
      }
      return `${gap}px`;
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > *': {
      width: '100%',
      flexShrink: '0',
      flexGrow: '1',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: ({ elementsPerRowDesktop, children }) => {
        if (size(children) <= elementsPerRowDesktop) {
          return 'center';
        }
        return 'flex-start';
      },
    },
  },
  controlDots: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  controlDot: {
    backgroundColor: theme.palette.common.mediumGray,
    height: '8px',
    marginBottom: '10px',
    marginLeft: '4px',
    width: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      width: '54px',
    },
  },
  controlDotActive: {
    backgroundColor: theme.overrides.MuiButton.containedPrimary.backgroundColor,
  },
  arrowIcon: {
    position: 'absolute',
    zIndex: '2',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '44px',
    height: '44px',
    backgroundColor: ({ arrowButtonVariant }) => {
      if (arrowButtonVariant === 'contained') {
        return 'null';
      }
      return theme.palette.background.default;
    },
    '@media (min-width: 1410px)': {
      top: '50%',
    },
    '@media print': {
      display: 'none',
    },
    ...theme.slider.arrowBotton,
  },
  icon: { color: theme.palette.text.primary },

  leftArrow: {
    left: '-14px',
    [theme.breakpoints.up(1344)]: {
      left: '-54px',
    },
  },

  rightArrow: {
    right: '-14px',
    [theme.breakpoints.up(1344)]: {
      right: '-54px',
    },
  },
});

function Slider({
  classes,
  className,
  children,
  elementsPerRowDesktop,
  elementsPerRowTablet,
  elementsPerRowMobile,
  sliderControls,
  gap,
  hideText,
  arrowButtonVariant,
}) {
  children = filter(children, child => {
    return !isEmpty(child);
  });
  const length = size(children);
  const [show, setShow] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const [steps, setSteps] = useState(1);
  const [transformPercentage, setTransformPercentage] = useState(100);
  const [gapMultiplier, setGapMultiplier] = useState(0);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 840));

  const stepsArray = new Array(steps).fill(undefined);
  useEffect(() => {
    if (isDesktop) {
      setTransformPercentage(100 / elementsPerRowDesktop);
      setShow(elementsPerRowDesktop);
      if (gap && !hideText) {
        setGapMultiplier(gap / elementsPerRowDesktop);
      }
    }
    if (isTablet) {
      setTransformPercentage(100 / elementsPerRowTablet);
      setShow(elementsPerRowTablet);
      if (gap && !hideText) {
        setGapMultiplier(gap / elementsPerRowTablet);
      }
    }
    if (!isDesktop && !isTablet) {
      setTransformPercentage(100 / elementsPerRowMobile);
      setShow(elementsPerRowMobile);
      if (gap && !hideText) {
        setGapMultiplier(gap / elementsPerRowMobile);
      }
    }
    if (length <= show) {
      setSteps(0);
    } else {
      setSteps(Math.ceil(length / show));
    }
  }, [
    isDesktop,
    isTablet,
    children,
    length,
    show,
    elementsPerRowDesktop,
    gap,
    hideText,
    elementsPerRowTablet,
    elementsPerRowMobile,
  ]);

  if (isEmpty(children)) {
    return null;
  }
  const handleNext = () => {
    if (currentIndex < length - show) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const handelPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  const handleStep = step => () => {
    if ((step + 1) * show <= length) {
      setCurrentIndex(step * show);
    } else {
      setCurrentIndex(length - show);
    }
  };

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = e => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }
    if (diff < -5) {
      handelPrev();
    }
    setTouchPosition(null);
  };

  return (
    <div className={clsx(classes.root, className)}>
      {isEqual(sliderControls, 'arrows') && (
        <Visible hidden={currentIndex === 0}>
          <Button
            variant={arrowButtonVariant}
            onClick={handelPrev}
            className={clsx(classes.arrowIcon, classes.leftArrow)}
            disabled={currentIndex === 0}>
            <ArrowBack fontSize="small" />
          </Button>
        </Visible>
      )}

      <div className={classes.carouselWrapper}>
        <div className={classes.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          <div
            className={classes.carouselContent}
            style={{
              transform: `translateX(-${currentIndex * transformPercentage}%) translateX(-${
                currentIndex * gapMultiplier
              }px)`,
            }}>
            {children}
          </div>
        </div>
      </div>
      {isEqual(sliderControls, 'arrows') && (
        <Visible hidden={currentIndex === length - show || length - show < 0}>
          <Button
            variant={arrowButtonVariant}
            onClick={handleNext}
            className={clsx(classes.arrowIcon, classes.rightArrow)}
            disabled={currentIndex === length - show || length - show < 0}>
            <ArrowForward fontSize="small" />
          </Button>
        </Visible>
      )}

      {isEqual(sliderControls, 'dots') && (
        <Stepper activeStep={currentIndex} className={classes.controlDots} connector={null}>
          {stepsArray.map((label, index) => (
            <Step
              key={`key_${index}`}
              onClick={handleStep(index)}
              className={clsx(
                classes.controlDot,
                isEqual(currentIndex / show, index) || (index > currentIndex / show && index < currentIndex / show + 1)
                  ? classes.controlDotActive
                  : null,
              )}
            />
          ))}
        </Stepper>
      )}
    </div>
  );
}

Slider.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  gradientColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
  sliderControls: PropTypes.string,
  arrowButtonVariant: PropTypes.string,
  hideText: PropTypes.bool,
};

Slider.defaultProps = {
  classes: {},
  className: null,
  gradientColor: '#ffffff',
  children: null,
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 2,
  elementsPerRowMobile: 1,
  sliderControls: 'arrows',
  arrowButtonVariant: 'outlined',
  hideText: false,
};

export default withStyles(styles)(Slider);
