import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import AnoraProMobileNavigation from './anoraPro';
import ViinimaaMobileNavigation from './viinimaa';
import FolkofolkMobileNavigation from './folkofolk';
import BlossaMobileNavigation from './blossa';
import LinieAquavitMobileNavigation from './linieAquavit';
import SkagerrakMobileNavigation from './skagerrak';
import OpAndersonMobileNavigation from './opAnderson';
import KoskenkorvaMobileNavigation from './koskenkorva';
import WineworldFinlandMobileNavigation from './wineworldFinland';

function MobileNavigation(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return <BlossaMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <OpAndersonMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaMobileNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <WineworldFinlandMobileNavigation {...props} />;
  }
  if (brandSite) {
    return <ViinimaaMobileNavigation {...props} />;
  }

  return null;
}

export default MobileNavigation;
