import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  withStyles,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem,
  InputLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';
import { Field } from 'formik';
import { isEqual } from 'lodash';

const styles = () => ({
  dropdown: {
    minWidth: '100%',
  },
});

function CustomField({ classes, fieldData, errors }) {
  const { t } = useTranslation();

  const fieldType = get(fieldData, 'fieldType');
  const id = get(fieldData, 'id');
  const labelText = get(fieldData, 'labelText');
  const placeholderText = get(fieldData, 'placeholderText');
  const predefinedValues = get(fieldData, 'predefinedValues');
  const required = get(fieldData, 'required', false);

  const requiredValidation = useCallback(
    value => {
      let error;
      if (!value) {
        error = t('formModule.errors.required');
      }
      return error;
    },
    [t],
  );

  const validateEmail = useCallback(
    value => {
      let error;
      if (!value) {
        error = t('CustobarSubscriptionForm.errors.emailAddress.required');
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = t('CustobarSubscriptionForm.errors.emailAddress.invalid');
      }
      return error;
    },
    [t],
  );

  switch (fieldType) {
    case 'short text' || 'long text': {
      return (
        <FormControl component="fieldset" error={Boolean(errors.shortText)} fullWidth required={required}>
          <Field
            id={id}
            validate={required ? requiredValidation : null}
            multiline={isEqual(fieldType, 'long text')}
            label={labelText}
            type="text"
            name={isEqual(fieldType, 'long text') ? 'longText' : 'shortText'}
            placeholder={placeholderText}
            title={placeholderText}
            component={TextField}
            disabled={false}
            required={required}
          />
        </FormControl>
      );
    }
    case 'email': {
      return (
        <FormControl component="fieldset" error={Boolean(errors.emailAddress)} fullWidth required={required}>
          <Field
            id={id}
            validate={validateEmail}
            label={labelText}
            type="email"
            name="emailAddress"
            placeholder={placeholderText}
            title={placeholderText}
            component={TextField}
            disabled={false}
            required={required}
          />
        </FormControl>
      );
    }
    case 'phone':
      return (
        <FormControl component="fieldset" error={Boolean(errors.phone)} fullWidth required={required}>
          <Field
            id={id}
            validate={required ? requiredValidation : null}
            label={labelText}
            type="tel"
            name="phone"
            placeholder={placeholderText}
            title={placeholderText}
            component={TextField}
            disabled={false}
            required={required}
          />
        </FormControl>
      );

    case 'radiobutton':
      return (
        <FormControl component="fieldset" error={Boolean(errors.radiobutton)} required={required}>
          <FormLabel component="legend">{labelText}</FormLabel>
          <RadioGroup aria-label={labelText} name={labelText}>
            {map(predefinedValues, value => {
              return (
                <div>
                  <Field
                    validate={required ? requiredValidation : null}
                    key={`${id}_${value}`}
                    type="radio"
                    name="radiobutton"
                    value={value}
                    label={value}
                    color="default"
                  />
                  <Typography variant="body2" component="span">
                    {value}
                  </Typography>
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    case 'checkbox':
      return (
        <FormControl component="fieldset" error={Boolean(errors.checked)} required={required} disabled={false}>
          <FormLabel component="legend">{labelText}</FormLabel>
          <FormGroup aria-label={labelText} name={labelText}>
            {map(predefinedValues, value => (
              <div>
                <Field
                  validate={required ? requiredValidation : null}
                  key={`${id}_${value}`}
                  type="checkbox"
                  name="checkbox"
                  color="default"
                  value={value}
                  Label={{ label: value }}
                />
                <Typography variant="body2" component="span">
                  {value}
                </Typography>
              </div>
            ))}
          </FormGroup>
        </FormControl>
      );
    case 'dropdown':
      return (
        <FormControl className={classes.dropdown} error={Boolean(errors.dropdown)} disabled={false} required={required}>
          <InputLabel id={id}>{labelText}</InputLabel>
          <Field name="dropdown" id={id} component={Select} validate={required ? requiredValidation : null}>
            {map(predefinedValues, value => {
              return (
                <MenuItem key={`${id}_${value}`} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
      );

    default:
      return null;
  }
}

CustomField.propTypes = {
  classes: PropTypes.object,
  fieldData: PropTypes.object,
  errors: PropTypes.any,
};

CustomField.defaultProps = {
  classes: {},
  fieldData: {},
  errors: null,
};

function FormFields({ classes, data, ...otherProps }) {
  return (
    <>
      {map(data, field => {
        const id = get(field, 'id');
        return <CustomField key={id} fieldData={field} classes={{ dropdown: classes.dropdown }} {...otherProps} />;
      })}
    </>
  );
}

FormFields.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  pageData: PropTypes.object,
  isSubmitted: PropTypes.bool,
  errors: PropTypes.any,
};
FormFields.defaultProps = {
  classes: {},
  data: null,
  pageData: null,
  isSubmitted: false,
  errors: null,
};
export default withStyles(styles)(FormFields);
