import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';

const styles = () => ({
  button: {
    margin: '0 16px 0 4px',
    padding: '2px 10px',
    height: '42px',
  },
});
function LoginButton({ className, classes, text, variant }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    !isAuthenticated && (
      <Button
        className={clsx(className, classes.button)}
        variant={variant}
        type="button"
        startIcon={<LoginIcon />}
        onClick={() => {
          loginWithRedirect();
        }}>
        {text || t('authentication.loginButton.label')}
      </Button>
    )
  );
}
LoginButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};
LoginButton.defaultProps = {
  classes: {},
  className: null,
  text: '',
  variant: 'text',
};

export default withStyles(styles)(LoginButton);
