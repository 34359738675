import React from 'react';

import isEqual from 'lodash/isEqual';

import { siteIds, subscribeNewsletterPage } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaSubscription from './viinimaa';

function Subscription(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaSubscription {...props} to={subscribeNewsletterPage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaSubscription {...props} to={subscribeNewsletterPage.Folkofolk} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return (
      <ViinimaaSubscription
        {...props}
        to={subscribeNewsletterPage.AnoraPro}
        text="CustobarSubscriptionForm.linkTextAnoraPro"
      />
    );
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return <ViinimaaSubscription {...props} to={subscribeNewsletterPage.Viinimaa} />;
  }

  return null;
}

export default Subscription;
