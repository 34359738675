import axios from 'axios';

import { navigate } from 'gatsby';

import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { siteId } from '../../sites';
import { subscribeNewsletterPage, siteIds } from '../../constants';

import { useMessage } from '../context/MessageContext';

const createCustobarSubscription = async (
  emailAddress,
  mailingLists,
  subscribePage,
  utmCampainID,
  subscribeNewsletterPageURL,
) => {
  const params = (() => {
    if (isEqual(subscribeNewsletterPageURL, subscribeNewsletterPage.Viinimaa)) {
      return {
        email: emailAddress,
        mailing_lists: mailingLists,
        altia__Subscribe_page: subscribePage,
        utm_campaign: utmCampainID,
      };
    }
    if (isEqual(subscribeNewsletterPageURL, subscribeNewsletterPage.Folkofolk)) {
      return {
        email: emailAddress,
        mailing_lists: mailingLists,
        'altia-se__subscribe_page': subscribePage,
        utm_campaign: utmCampainID,
      };
    }
    return {
      email: emailAddress,
      mailing_lists: mailingLists,
    };
  })();
  return axios.post(`${process.env.GATSBY_ELASTIC_HOST}/subscribetoemail`, [params]);
};

export const useCreateCustobarSubscription = () => {
  const { createSuccessMessage, createErrorMessage } = useMessage();
  const { t } = useTranslation();

  return useMutation(
    ({ emailAddress, mailingLists, subscribePage, utmCampainID, subscribeNewsletterPageURL }) => {
      return createCustobarSubscription(
        emailAddress,
        mailingLists,
        subscribePage,
        utmCampainID,
        subscribeNewsletterPageURL,
      );
    },
    {
      onError: () => {
        createErrorMessage(t('useCreateCustobarSubscription.messages.error'));
      },
      onSuccess: () => {
        if (isEqual(siteId, siteIds.Viinimaa) && !isEmpty(subscribeNewsletterPage.ViinimaaRedirectURL)) {
          navigate(subscribeNewsletterPage.ViinimaaRedirectURL);
        } else if (isEqual(siteId, siteIds.Folkofolk) && !isEmpty(subscribeNewsletterPage.FolkofolkRedirectURL)) {
          navigate(subscribeNewsletterPage.FolkofolkRedirectURL);
        } else {
          createSuccessMessage(t('useCreateCustobarSubscription.messages.success'));
        }
      },
      retry: false,
    },
  );
};

export const useCreateCampaignSubscription = () => {
  const { createSuccessMessage, createErrorMessage } = useMessage();
  const { t } = useTranslation();

  return useMutation(
    ({ emailAddress, mailingLists, subscribePage, utmCampainID }) => {
      return axios.post(`${process.env.GATSBY_ELASTIC_HOST}/subscribetoemail`, [
        {
          email: emailAddress,
          mailing_lists: mailingLists,
          'altia-se__subscribe_page': subscribePage,
          utm_campaign: utmCampainID,
        },
      ]);
    },
    {
      onError: () => {
        createErrorMessage(t('useCreateCustobarSubscription.messages.error'));
      },
      onSuccess: () => {
        createSuccessMessage(t('CampaignPage.Newsletter.ThankYouMessage'));
      },
      retry: false,
    },
  );
};
