import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import AnoraProProfile from './common';

export default function Profile(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProProfile {...props} />;
  }

  return null;
}
