import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';

import { withStyles, Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    display: 'block',
    marginTop: '5px',
    margin: theme.spacing(1, 0),
  },
  accordion: {
    backgroundColor: theme.accordion.backgroundColor,
    color: theme.accordion.color,
    boxShadow: 'none',
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    paddingLeft: '0px',
  },
  expandIcon: {
    backgroundColor: theme.accordion.expandIcon.backgroundColor,
    borderRadius: '0',
    marginRight: '15px',
    padding: '7px',
    color: theme.accordion.color,
    '&:hover': {
      backgroundColor: theme.accordion.expandIcon.hover.backgroundColor,
    },
    transform: 'none',
    transition: 'none',
  },
});

function AccordionModule({ classes, className, data, richText: RichText }) {
  const title = get(data, 'title');
  const description = get(data, 'description');
  const openByDefault = get(data, 'openByDefault');

  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const handleTitleClick = React.useCallback(() => {
    setAccordionOpen(prevAccordionOpen => !prevAccordionOpen);
  }, []);

  React.useEffect(() => {
    setAccordionOpen(openByDefault);
  }, [openByDefault]);

  return (
    <div className={clsx(classes.root, className)}>
      <Accordion
        classes={{ root: classes.accordion }}
        className={classes.heading}
        expanded={accordionOpen}
        onClick={handleTitleClick}>
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RichText textData={description} className={classes.content} />
        </AccordionDetails>
      </Accordion>
      <Divider />
    </div>
  );
}

AccordionModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

AccordionModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(AccordionModule);
