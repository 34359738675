import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { Launch } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { productClick, purchaseProduct, purchaseProductFromCart } from '../../../../../utils/analyticUtils';

import Button from '../../../Button';
import Visible from '../../../Visible';

const styles = theme => ({
  root: {
    ...theme.palette.button,
    display: 'flex',
    width: '100%',
    padding: '13px 15px',
    fontSize: '12px',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
    },
    '@media print': {
      display: 'none',
    },
  },
  missingLink: {},
});

function ProductMonopolyLink({ className, classes, productData, location, ...otherProps }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['cb']);

  const monopolyUrl = get(productData, 'attributes.monopoly_url.value');
  const customerToken = cookies.cb ? cookies.cb.token : '';

  const handleAvailabilityButtonClick = useCallback(() => {
    productClick(productData);
  }, [productData]);

  const handleButtonClick = useCallback(() => {
    purchaseProduct(productData, customerToken);
    if (location === 'memoryList') {
      purchaseProductFromCart();
    }
  }, [productData, customerToken, location]);

  return (
    <>
      <Visible visible={!isEmpty(monopolyUrl)}>
        <Button
          {...otherProps}
          target="_blank"
          className={clsx(classes.root, className)}
          to={monopolyUrl}
          endIcon={<Launch fontSize="small" />}
          onClick={handleButtonClick}>
          {t('ProductMonopolyLink.label')}
        </Button>
      </Visible>
      <Visible visible={isEmpty(monopolyUrl)}>
        <Button
          disabled
          {...otherProps}
          target="_blank"
          className={clsx(classes.root, className)}
          to={null}
          onClick={handleAvailabilityButtonClick}>
          {t('ProductMonopolyLink.missingLink.label')}
        </Button>
      </Visible>
    </>
  );
}

ProductMonopolyLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  location: PropTypes.string,
};

ProductMonopolyLink.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  location: null,
};

export default withStyles(styles)(ProductMonopolyLink);
