import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

import { Link, Typography, withStyles } from '@material-ui/core';

import ThumbnailImage from '../../../shared/ThumbnailImage';

function encodePhoneNumber(phoneNumber) {
  return encodeURIComponent(replace(phoneNumber, /\s/g, ''));
}

const styles = theme => ({
  root: {
    margin: '2rem 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'unset',
      flexDirection: 'row',
    },
  },
  imageContainer: {
    display: 'flex',
  },

  text: {
    flex: '1 0 auto',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'unset',
      padding: '0 2rem',
    },
  },
  title: {
    marginBottom: '10px',
  },
  subtitle: {
    marginBottom: '15px',
    fontStyle: 'italic',
  },
  link: {
    display: 'block',
  },
  shareButton: {
    alignSelf: 'flex-end',
  },
  shareIcon: {
    marginRight: '5px',
  },
});

function ContactPerson({ classes, className, data }) {
  const name = get(data, 'name');
  const role = get(data, 'role');
  const emailAddress = get(data, 'emailAddress');
  const phoneNumber = get(data, 'phoneNumber');
  const description = get(data, 'description.description');
  const encodedPhoneNumber = useMemo(() => encodePhoneNumber(phoneNumber), [phoneNumber]);

  return (
    <article className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          <ThumbnailImage data={data} transformation="ContactPersonImage-MD-MD" width="150" height="150" />
        </div>
        <div className={classes.text}>
          <Typography variant="h3" className={classes.title}>
            {name}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {role}
          </Typography>
          {!isEmpty(description) && (
            <Typography variant="body1" className={classes.title}>
              {description}
            </Typography>
          )}
          <Link href={`mailto:${emailAddress}`} className={classes.link}>
            {emailAddress}
          </Link>
          <Link href={`tel:${encodedPhoneNumber}`} className={classes.link}>
            {phoneNumber}
          </Link>
        </div>
      </div>
    </article>
  );
}

ContactPerson.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

ContactPerson.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(ContactPerson);
