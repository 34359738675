import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { get, map, isEmpty } from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import { useDialog } from '../../../context/DialogContext';
import { useMemoryList } from '../../../hooks/useMemoryList';
import { productClick, removeFromCart, viewCart } from '../../../../utils/analyticUtils';

import Link from '../../../navigation/Link';
import Visible from '../../../shared/Visible';
import ProductMonopolyPrice from '../../../shared/Products/ProductMonopolyPrice';
import ProductMonopolyLink from '../../../shared/Products/ProductMonopolyLink';

const styles = theme => ({
  root: { borderBottom: '1px solid black' },
  dialogTitle: { margin: 0, padding: theme.spacing(2) },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  table: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  mobileTable: {
    borderBottom: '1px solid gray',
  },
});

const StyledTableCell = withStyles(theme => ({
  head: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
    fontWeight: 'bold',
  },
}))(TableCell);

function MemoryListDialog({ classes, className, pageData, ...otherProps }) {
  const { t } = useTranslation();
  const { isDialogOpen, closeDialog } = useDialog();
  const { getProductsInMemoryList, removeProductFromMemoryList } = useMemoryList();
  const products = getProductsInMemoryList(pageData);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogOpen = isDialogOpen('MemoryListDialog');

  useEffect(() => {
    if (dialogOpen) {
      viewCart(products);
    }
  }, [products, dialogOpen]);

  const handleLinkClick = useCallback(
    productData => () => {
      productClick(productData);
      closeDialog();
    },
    [closeDialog],
  );

  const handleRemoveFromList = useCallback(
    productData => () => {
      removeFromCart(productData);
      removeProductFromMemoryList(productData);
    },
    [removeProductFromMemoryList],
  );

  return (
    <Dialog
      className={clsx(classes.root, className)}
      maxWidth="lg"
      fullScreen={fullScreen}
      fullWidth
      open={dialogOpen}
      onClose={closeDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5">{t('MemoryList.MemoryListDialog.title')}</Typography>
        <IconButton className={classes.closeButton} onClick={closeDialog}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Visible visible={isEmpty(products)}>
          <Typography>{t('MemoryList.MemoryListDialog.empty.text')}</Typography>
        </Visible>
        <Visible visible={!isEmpty(products)}>
          <Visible visible={fullScreen}>
            <Table>
              {map(products, product => (
                <TableBody key={get(product, 'sku')}>
                  <TableRow>
                    <TableCell>{t('MemoryList.MemoryListDialog.fields.name.label')}</TableCell>
                    <TableCell>
                      <Link to={get(product, 'fullPath')} onClick={handleLinkClick(product)}>
                        {get(product, 'name')}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('MemoryList.MemoryListDialog.fields.monopolyNumber.label')}</TableCell>
                    <TableCell>{get(product, 'attributes.monopoly_number.value')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('MemoryList.MemoryListDialog.fields.monopolyPrice.label')}</TableCell>
                    <TableCell>
                      <ProductMonopolyPrice productData={product} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('MemoryList.MemoryListDialog.actions.remove.label')}</TableCell>
                    <TableCell>
                      <IconButton onClick={handleRemoveFromList(product)}>
                        <Close />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow classes={{ root: classes.root }}>
                    <TableCell colSpan="2">
                      <ProductMonopolyLink productData={product} {...otherProps} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </Visible>
          <Visible visible={!fullScreen}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead} classes={{ root: classes.root }}>
                <TableRow>
                  <StyledTableCell>{t('MemoryList.MemoryListDialog.fields.name.label')}</StyledTableCell>
                  <StyledTableCell>{t('MemoryList.MemoryListDialog.fields.monopolyNumber.label')}</StyledTableCell>
                  <StyledTableCell>{t('MemoryList.MemoryListDialog.fields.monopolyPrice.label')}</StyledTableCell>
                  <StyledTableCell>{t('MemoryList.MemoryListDialog.actions.remove.label')}</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {map(products, product => (
                  <TableRow key={get(product, 'sku')}>
                    <TableCell>
                      <Link to={get(product, 'fullPath')} onClick={handleLinkClick(product)}>
                        {get(product, 'name')}
                      </Link>
                    </TableCell>
                    <TableCell>{get(product, 'attributes.monopoly_number.value')}</TableCell>
                    <TableCell>
                      <ProductMonopolyPrice productData={product} />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={handleRemoveFromList(product)}>
                        <Close />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <ProductMonopolyLink productData={product} {...otherProps} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Visible>
        </Visible>
      </DialogContent>
    </Dialog>
  );
}

MemoryListDialog.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MemoryListDialog.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(MemoryListDialog);
