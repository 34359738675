import React from 'react';

import { isEqual } from 'lodash';

import { siteIds, productDefaultImage } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductDescriptionCard from './viinimaa';
import FolkofolkProductDescriptionCard from './folkofolk';

function ProductDescriptionCard(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductDescriptionCard {...props} defaultImage={productDefaultImage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaProductDescriptionCard {...props} defaultImage={productDefaultImage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkProductDescriptionCard {...props} defaultImage={productDefaultImage.Folkofolk} />;
  }

  return null;
}

export default ProductDescriptionCard;
