import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';

import { Badge, IconButton, withStyles } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useDialog } from '../../../context/DialogContext';
import { useMemoryList } from '../../../hooks/useMemoryList';

const styles = theme => ({
  root: {
    display: 'flex',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 16px',
    },
  },
  badge: {
    '& span': {
      ...theme.palette.memoryListButton.badge,
    },
  },
});

function MemoryListIconButton({ classes, className, pageData }) {
  const { t } = useTranslation();
  const memoryListPageURL = get(pageData, 'mainTheme.memoryListPage.fullPath');

  const { openDialog } = useDialog();
  const { prodcutCountInMemoryList } = useMemoryList();

  const handleButtonClick = useCallback(() => {
    if (isEmpty(memoryListPageURL)) {
      openDialog('MemoryListDialog');
    } else {
      navigate(memoryListPageURL);
    }
  }, [memoryListPageURL, openDialog]);

  if (prodcutCountInMemoryList <= 0) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <IconButton
        className={classes.iconButton}
        onClick={handleButtonClick}
        aria-label={t('MemoryList.MemoryListIconButton.label')}>
        <Badge overlap="rectangular" className={classes.badge} badgeContent={prodcutCountInMemoryList} showZero>
          <ListAlt />
        </Badge>
      </IconButton>
    </div>
  );
}

MemoryListIconButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MemoryListIconButton.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default memo(withStyles(styles)(MemoryListIconButton));
