import React from 'react';

import { isEqual } from 'lodash';
import Language from '@material-ui/icons/Language';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaAccordionModule from './viinimaa';
import BrandsAccordionModule from './brands';

function AccordionModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaAccordionModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaAccordionModule {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaAccordionModule {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <BrandsAccordionModule {...props} expandIcon="plus" />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <BrandsAccordionModule {...props} expandIcon="plus" />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <BrandsAccordionModule {...props} startIcon={<Language />} expandIcon="plus" />;
  }
  if (brandSite) {
    return <BrandsAccordionModule {...props} />;
  }

  return null;
}

export default AccordionModule;
