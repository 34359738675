import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import CommonRegistrationButton from './common';

export default function RegistrationButton(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <CommonRegistrationButton {...props} />;
  }

  return null;
}
