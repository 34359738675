import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, map, isEqual, filter, split } from 'lodash';
import { withStyles } from '@material-ui/core';

import CloudinaryImage from '../../../CloudinaryImage';
import { siteIds } from '../../../../../constants';
import { siteId } from '../../../../../sites';
import Link from '../../../../navigation/Link';

const styles = () => ({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '0px 8px',
  },
  labels: {},
  image: {
    margin: '8px',
    '@media print': {
      height: 'auto',
      maxWidth: '40px',
    },
  },
});

// product_classification attribute
/*
Viinimaa labels =>
vegan,
Luomu,
Biodynaaminen,
Paikallinen Ekosertifiointi,
Fair Trade,Hiilineutraali,
Alhainen sokeripitoisuus
 */

/*
folkofolk labels =>
Veganvänlig,
Ekologisk,
Biodynamisk,
Lokalt miljöcertifikat,
Etiskt val,
Hållbart val,
Låg sockerhalt"
*/

function ProductClassification({ className, classes, productData, linkUrl, width, height, ...otherProps }) {
  const classifications = get(productData, 'attributes.product_classification.value');
  const classificationList = split(classifications, ',');

  const images = useMemo(() => {
    if (isEqual(siteId, siteIds.Viinimaa) || isEqual(siteId, siteIds.AnoraPro)) {
      return [
        { key: 'Vegan', value: 'viinimaa/labels/vegaani.svg' },
        { key: 'Biodynaaminen', value: 'viinimaa/labels/biodynaaminen.svg' },
        { key: 'Eettinen', value: 'viinimaa/labels/eettinen-viini.svg' },
        { key: 'Hiilineutraali', value: 'viinimaa/labels/hiilineutraali.svg' },
        { key: 'Luomu', value: 'viinimaa/labels/luomu.svg' },
        { key: 'Paikallinen Ekosertifiointi', value: 'viinimaa/labels/paikallinen-ekosertifiointi.svg' },
      ];
    }
    return [
      { key: 'Vegan', value: 'folkofolk/labels/vegan.svg' },
      { key: 'Hållbart val', value: 'folkofolk/labels/hallbart-val.svg' },
      { key: 'Mindre socker', value: 'folkofolk/labels/mindre-socker.svg' },
      { key: 'Etisk', value: 'folkofolk/labels/etisk.svg' },
      { key: 'Ekologisk', value: 'folkofolk/labels/eko.svg' },
      { key: 'Klimatsmart', value: 'folkofolk/labels/klimat-smart.svg' },
    ];
  }, []);

  if (isEmpty(classifications)) {
    return null;
  }

  return (
    <div {...otherProps} className={clsx(classes.root, className)}>
      <Link to={linkUrl}>
        {map(classificationList, (label, i) => {
          const imageData = filter(images, ['key', label]);
          return (
            <CloudinaryImage
              key={i}
              className={clsx(classes.image)}
              publicId={get(imageData, '0.value')}
              transformation="ProductClassification-SM-SM"
              alt={get(imageData, '0.key')}
              title={get(imageData, '0.key')}
              width={width}
              height={height}
            />
          );
        })}
      </Link>
    </div>
  );
}

ProductClassification.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  linkUrl: PropTypes.string,
};

ProductClassification.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  width: '80',
  height: '80',
  linkUrl: null,
};

export default withStyles(styles)(ProductClassification);
