import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { camelCase, get, startsWith } from 'lodash';
import { withStyles } from '@material-ui/core';

import CustobarSubscriptionForm from '../CustobarSubscriptionForm';
import CustomForm from '../CustomForm/CustomForm';

const styles = () => ({
  root: {
    marginBottom: '0px',
    '@media print': {
      display: 'none',
    },
  },
  title: {},
});

function Form({ type, ...otherProps }) {
  if (startsWith(type, 'custobarSubscription')) {
    return <CustobarSubscriptionForm {...otherProps} />;
  }
  return <CustomForm {...otherProps} />;
}

Form.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
};

Form.defaultProps = {
  type: null,
  data: null,
};

function FormModule({ classes, className, data, ...otherProps }) {
  const type = camelCase(get(data, 'formType.title'));
  return (
    <div className={clsx(classes.root, className)}>
      <Form type={type} data={data} {...otherProps} />
    </div>
  );
}

FormModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

FormModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(FormModule);
