import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { IconButton, withStyles, MenuItem, Popper, Grow, ClickAwayListener, MenuList, Paper } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import { anoraProUnprotectedPages } from '../../../../constants';

const styles = theme => ({
  icon: {
    fontSize: '1.7rem',
  },
  buttons: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
  },
});

function Profile({ classes }) {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = event => {
    handleClose(event);

    if (isAuthenticated) {
      const logoutUrl = `${window.location.origin}${anoraProUnprotectedPages.login}?loggedOut=true`;
      logout({ logoutParams: { returnTo: logoutUrl } });
    } else {
      loginWithRedirect();
    }
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label={t('authentication.accountIconButton.label')}
        onClick={handleToggle}>
        <PersonOutlineIcon className={classes.icon} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                      {!isAuthenticated ? <LoginIcon /> : <LogoutIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        !isAuthenticated
                          ? t('authentication.loginButton.label')
                          : t('authentication.logoutButton.label')
                      }
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

Profile.propTypes = { classes: PropTypes.object, className: PropTypes.string };

Profile.defaultProps = { classes: {}, className: null };

export default withStyles(styles)(Profile);
