import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '@material-ui/core/Button';

import CreateIcon from '@material-ui/icons/Create';

function RegistrationButton({ className, classes, text, variant }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    !isAuthenticated && (
      <Button
        className={clsx(className, classes.button)}
        variant={variant}
        type="button"
        startIcon={<CreateIcon />}
        onClick={() => {
          loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
        }}>
        {text || t('authentication.RegistrationButton.label')}
      </Button>
    )
  );
}
RegistrationButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};
RegistrationButton.defaultProps = {
  classes: {},
  className: null,
  text: '',
  variant: 'text',
};

export default RegistrationButton;
