import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import CommonLoginButton from './common';

export default function LoginButton(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <CommonLoginButton {...props} />;
  }

  return null;
}
